<template lang="pug">
z-table(:columns="columns" 
    :uploadAble="true"
    @beforeUpload="beforeUpload"
    ref="table"
    :route="`hkoption/${bookName}/stock`"
    rowKey="id") 
</template>

<script>
import _ from 'lodash';

export default {
    props: ['bookName'],
    data() {
        return {
            columns: [
                {
                    title: 'Stock Code',
                    dataIndex: 'stock_code',
                    addAble: true,
                    searchAble: true,
                    scopedSlots: { customRender: 'inst' },
                },
                {
                    title: 'Direction',
                    dataIndex: 'direction',
                    addAble: true,
                    searchAble: true,
                    type: 'Enum',
                    enum: ['BUY', 'SELL'],
                },
                {
                    title: 'Current Stock Contract',
                    dataIndex: 'curr_stock_contract',
                    type: 'Number',
                    addAble: true,
                    editAble: true,
                },
                {
                    title: 'Price Cost',
                    dataIndex: 'price_cost',
                    type: 'Number',
                    addAble: true,
                    editAble: true,
                },
                {
                    title: 'Cumulative Stock Pnl',
                    dataIndex: 'cum_stock_pnl',
                    type: 'Number',
                    addAble: false,
                    editAble: false,
                },
                {
                    title: 'Realized Pnl',
                    dataIndex: 'realized_pnl',
                    type: 'Number',
                    addAble: true,
                    editAble: true,
                },
                {
                    title: 'Floating Pnl',
                    dataIndex: 'floating_pnl',
                    addAble: false,
                    editAble: false,
                },
                {
                    title: 'Daily Pnl',
                    dataIndex: 'stock_daily_pnl',
                    addAble: false,
                    editAble: false,
                }
            ]
        }
    },
    methods: {
        beforeUpload(file) {
            let fr = new FileReader();

            let p = new Promise((resolve, reject) => {
                fr.onload = () => {
                    resolve(fr.result);
                };
                fr.onerror = reject;
            });
            
            fr.readAsText(file);

            p.then(data => {
                data = data.trim();
                let header = data.split('\n')[0]
                    .replace('current stock contract', 'curr_stock_contract')
                    .replace('price cost', 'price_cost')
                    .replace('stock code', 'stock_code')
                    .split(',').map(h => h.trim());

                let values = _.chain(data.split('\n').slice(1))
                    .map(l => l.split(',').map(h => h.trim()))
                    .map(l => _.zipObject(header, l))
                    .map(l => ({
                        ...l,
                        price_cost: parseFloat(l.price_cost),
                        curr_stock_contract: parseFloat(l.curr_stock_contract),
                        realized_pnl: 0,
                    }))
                    .value();
                let exist_codes = _.chain(this.$refs.table.items)
                    .map(i => [i.stock_code, i.id])
                    .fromPairs()
                    .value();
                    
                let addPromise = Promise.all(values.map(async val => {
                    let id = exist_codes[val.stock_code];

                    if (id) {
                        await this.$api.request('POST', `hkoption/${this.bookName}/stock/update`, {
                            ...val,
                            id,
                        });
                    }
                    else {
                        await this.$api.request('POST', `hkoption/${this.bookName}/stock/create`, val);
                    }
                    
                }));
                
                addPromise.then(() => {
                    this.$message.success('添加成功');
                    this.$refs.table.getData(true);
                })
                .catch(() => {
                    this.$message.error('添加失败');
                    this.$refs.table.getData(true);
                });

            })
        }
    }
}
</script>
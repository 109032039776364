<template lang="pug">
.page
    a-modal(v-model="addSubGroupVisiable"
        title="新建文件夹"
        @ok="submitAddSubGroup")
        a-input(v-model="newSubGroup")

    h3.empty-folder(v-if="groups.length == 0") 文件夹为空，请联系管理员！
    .folder(v-else)
        .folder-tree
            //- a.folder-root-item(v-for="g in groups" 
            //-     :key="g.name" 
            //-     @click="gotoGroup(g.name)" style="background-color:white") 

            //-     a-icon(type="folder" 
            //-         theme="filled" 
            //-         style="color:#79cdfd" 
            //-         v-if="currentGroup != g.name")
            //-     a-icon(type="folder-open" style="color:#79cdfd" theme="filled" v-else)
            //-     span {{g.name}}
            a-menu(@click="(e) => gotoGroup(e.key)" style="padding-top:0px" theme="dark")
                a-menu-item(v-for="g in groups" 
                    :key="g.name")
                    a-icon(type="folder" 
                        theme="filled" 
                        style="color:#79cdfd" 
                        v-if="currentGroup != g.name")
                    a-icon(type="folder-open" style="color:#79cdfd" theme="filled" v-else)
                    span {{g.name}}


        .folder-content
            .folder-header
                a-breadcrumb(style="flex:1")
                    a-breadcrumb-item 
                        a(@click="gotoRoot")
                            a-icon(type="folder" theme="filled" style="color:#79cdfd")
                            span {{currentGroup}}
                    a-breadcrumb-item(v-if="currentSubGroup != 'root'")
                        span {{currentSubGroup}}
                a.folder-ctl(@click="addSubGroupVisiable=true")
                    a-icon(type="folder-add")
            //- a-row(v-if="currentSubGroup == 'root'") 
            //-     a-col(:sm="3" v-for="g in subGroups" :key="g" v-if="g != 'root'" :md="2")
            //-         a.folder-item(@click="gotoSub(g)")
            //-             a-icon(type="folder" style="font-size:30px;color:#79cdfd" theme="filled")
            //-             div {{g}} 
                

            Folder(:group="currentGroup" :subGroup="currentSubGroup" :subGroups="subGroups" :key="groupKey" v-if="currentGroup!=''" @changeSubGroup="gotoSub")

</template>
<script>
import {mapState} from 'vuex';
import Folder from '../components/folder/Folder.vue';

export default {
    data() {
        return {
            addSubGroupVisiable: false,
            groupKey: '',
            newSubGroup: '',
            currentGroup: '',
            currentSubGroup: '',
            groups: [],
            
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        gotoGroup(group) {
            this.currentGroup = group;
            this.currentSubGroup = 'root';
            this.groupKey = group;
        },
        gotoSub(subGroup) {
            this.currentSubGroup = subGroup;
            this.groupKey = this.currentGroup + '/' + subGroup;
        },
        gotoRoot() {
            this.currentSubGroup = 'root';
            this.groupKey = this.currentGroup;
        },
        handleSelect(key) {
            const keyValue = key[0];
            const keyArr = keyValue.split('/');
        
            this.currentGroup = keyArr[0];
            if (keyArr.length > 1)
                this.currentSubGroup = keyArr[1];
            else {
                this.currentSubGroup = 'root';
                
            }
            this.groupKey = keyValue;
        },
        submitAddSubGroup() {
            this.$api.request('POST', 'subGroup/add/' + this.currentGroup, {subGroup: this.newSubGroup}, 'fs')
                .then(res => {
                    this.getData();
                    this.addSubGroupVisiable = false;
                });
        },
        submitDelSubGroup(subGroup) {
            this.$api.request('POST', 'subGroup/del/' + this.currentGroup, {subGroup}, 'fs')
                .then(res => {
                    this.getData();
                });
        },
        getData() {
            this.$api.request('GET', 'groups/' + this.me._id, {}, 'fs')
                .then(res => {
                    this.groups = res.data;
                    // if (!this.currentGroup && this.groups.length > 0) {
                    //     this.currentGroup = this.groups[0]['name'];
                    // }
                });
        },  
        handleEdit(targetKey, action) {
            console.log(targetKey, action);
            if (action == 'add') this.addSubGroupVisiable = true;
            else if (action == 'remove') {
                this.$confirm({
                    title: '确定删除' + targetKey + '?',
                    onOk: () => {
                        this.submitDelSubGroup(targetKey);
                    }
                });
            }
        }
    },
    computed: {
        ...mapState('user', ['me']),
        subGroups() {
            let g = this.groups.find(g => g.name == this.currentGroup)
            if (!g) return [];

            return g.subGroups ;
        }
    },
    components: {
        Folder
    }
}
</script>

<style lang="less" scoped>
.empty-folder{
    display: block;
    margin: auto;
    width: 100%;
    text-align: center;
    font-size: 20px;
}
.folder {
    display: flex;
    flex-direction: row;
}
.folder-tree {
    width: 300px;
    background-color: #001529;
    height: calc(100vh - 50px);
    /* height: 100vh - 40px; */
    overflow: auto;
    color: white;
}
.folder-content {
    flex: 1;
}
.folder-header {
    padding: 0px 10px;
    background-color: rgb(230, 230, 230);
    font-size: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .folder-ctl {
        font-size: 25px;
    }
}
.folder-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-bottom: 10px;
    margin: 0 20px 10px 20px;
    
}
.folder-item:hover {
    background-color: rgb(211, 211, 211);
}
.folder-root-item {
    display: block;
    font-size: 18px;
    padding: 8px;
    // margin: 3px 0;
    border: solid 1px #ddd;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.folder-root-item:hover {
    background-color: white;
}
</style>
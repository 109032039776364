<template lang="pug">
div   
    a-drawer(:visible="visible" 
        @close="visible=false" 
        destroyOnClose
        width="70%"
        :title="name")
        v-chart.chart(:option="option" :autoresize="true" v-for="option, index in Options" :key="index")
        //- z-chart
        

        a-input-search(placeholder="input search text" 
            style="width: 200px; margin-right:20px" 
            v-model="search")

        a-table(:columns="columns" 
            size="small"
            :dataSource="filteredItems" 
            :pagination="{ pageSize: 20}" 
            :loading="loading")
    a(@click="load")
        a-icon(type="file-excel" style="padding-right: 3px") 
        span {{name}}
</template>

<script>
import VChart, {THEME_KEY} from 'vue-echarts';
import _ from 'lodash';
import axios from 'axios';
const host = '/api/fs';

export default {
    props: ['name', 'id', 'pltOptions'],
    data() {
        return {
            items: [],
            datasource: [],
            columns: [],
            search: '',
            visible: false,
            loading: false,
        }
    },
    methods: {
        async load() {
            this.visible = true;
            this.loading = true;
            try {
                let res = await axios.get(`${host}/view/csv/${this.id}`, this.$api.jwtHeader());
                this.items = res.data;
                this.columns = _.keys(res.data[0]).map(key => {
                    return {
                        title: key,
                        dataIndex: key,
                        key: key,
                    }
                });
                this.loading = false;
            } catch (error) {
                this.$message.error('fetch data fail');
            }
        }
    },
    computed: {
        filteredItems() {
            return this.items.filter(item => {
                return Object.values(item).some(value => {
                    return String(value).includes(this.search);
                });
            });
        },
        Options() {
            let rlt = this.pltOptions.map((o, i) => {
                let series = o.y.map(j => {
                    return {
                        encode: {
                            x: o.x,
                            y: j,
                        },
                        type: 'line',
                        name: j,
                    };
                });
                return {
                    dataset: {
                        source: this.items,
                    },
                    series,
                    xAxis: {
                        type: 'category',
                    },
                    yAxis: {scale: true},
                    dataZoom: [{
                        type: 'inside',
                        zoomOnMouseWheel: true,
                        moveOnMouseMove: true,
                        moveOnMouseWheel: false,
                    }],
            
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross'
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                }
            });

            console.log(rlt);
            return rlt;
            
        }
    },
    components: {
        VChart
    }
}
</script>

<style lang="less" scoped>
.chart {
    height: 250px;
    width: 100%;
    // margin: 5px;
}
</style>

<template lang="pug">
.nav-header-content
    a-modal(title="重置密码" 
        v-if="!!me"
        @ok="submitChangePass"
        @cancel="resetPassVisible=false"
        :closable="!me.shouldResetPassword"
        :visible="resetPassVisible || me.shouldResetPassword")
        a-form(:form="changePassForm")
            a-form-item(label="新密码")
                a-input(type='password' v-decorator="['newPassword',{rules: [{required: true}]}]")

    a-dropdown
        .nav-item(@click="e => e.preventDefault()")
            a-icon(type="menu" )

        a-menu(slot="overlay")
            a-menu-item(v-for="i in $perms" 
                :key="i.value" 
                @click="goto(i.value, i.label)"
                v-if="me&& me.perms.includes(i.value) && !i.invisiable")
                my-icon(v-if="i.iconCustom" :type="i.iconType")
                a-icon(v-if="!i.iconCustom" :type="i.iconType")
                span {{ i.label }}
                
        //- a-icon(:type="collapsed ? 'menu' : 'menu'")
    
    .title {{navTitle}}
    .empty
    //- .nav-item(@click="goto('market', '行情')")
    //-     a-icon(type="stock")
    .nav-item(@click="goto('trade', '交易系统')")
        a-icon(type="fund")
        span(style="font-size:14px;margin-left: 5px") 交易系统

    .nav-item(@click="goto('folders', '文件系统')")
        a-icon(type="database")
        span(style="font-size:14px;margin-left:5px") 文件系统
        
    .nav-item 
        a-dropdown
            span(@click="e => e.preventDefault()")
                a-icon(type='user')
                span(style="margin: 0 5px") {{me && me.name}}
                a-icon(type="down")

            a-menu(slot="overlay" @click="handelUserMenu")
                a-menu-item(key="resetPass")
                    a-icon(type='key')
                    span 重置密码
                a-menu-item(key="logout")
                    a-icon(type="logout")
                    span 退出
</template>

<script>
import { mapState } from 'vuex';
import MyIcon from './MyIcon';
import _ from 'lodash';

export default {
    data() {

        return {
            collapsed: false,
            changePassForm: this.$form.createForm(this, {name: 'resetForm'}),
            resetPassVisible: false,
        }
    },
    methods: {
        goto(key, title) {
            this.title = title;
            this.$router.replace(`/main/${key}`);
            // this.$store.commit('nav/setVisible', false);
        },
        collapsedToggle() {
            this.collapsed = !this.collapsed;
        },
        handelUserMenu(e) {
            if (e.key == 'logout') {
                this.logout();
            }
            else if (e.key == 'resetPass') {
                this.resetPassVisible = true;
            }
        },
        logout() {
            this.$api.request('POST', 'logout').then(() => {
                this.$store.commit('user/setMe', null);
                this.$router.replace('/login');
            });
        },
        submitChangePass() {
            this.changePassForm.validateFields((error, values) => {
                if (!error) {
                    if (values.newPassword.length < 6) {
                        this.$message.error('密码长度最少6位！');
                        return;
                    }

                    this.$api
                        .request('POST', 'resetPass', {
                            password: values.newPassword,
                        })
                        .then(() => {
                            this.resetPassVisible = false;
                            this.$message.success('修改成功');
                        });
                }
            });
        }
    },
    computed: {
        ...mapState('user', ['me', 'navTitle']),
    },
    components: {
        MyIcon,
    },
}
</script>


<style lang="less" scoped>
.nav-header-content {
    color: white;
    height: 40px;
    background-color: #292961;
    width: 100%;
    border-bottom: solid 1px gray;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 10px;
    .collapse-menu {
        font-size: 30px;
        
    }
    .empty {
        flex: 1;
    }

    .title {
        margin-left: 10px;
    }

    .nav-item {
        margin: 2px 5px;
        padding: 0 10px;
        font-size: 20px;
        border-radius: 5px;
        text-align: center;
        cursor: pointer;
        display: inline-block;
        background-color: #71719e;
        :hover {
            border-radius: 0px;
        }
    }
}
</style>
